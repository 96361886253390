import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';
import { SharedStorageService } from 'src/app/modules/shared/services/shared-storage.service';
import { UserDataService } from 'src/app/modules/core/user-service/user-data.service';
import { AccountUserActions } from 'src/app/modules/core/user-service/user.model';

@Injectable({
  providedIn: 'root',
})

export class UserPermissionService {

  constructor(private userDataService: UserDataService, private sharedStorageService: SharedStorageService, private appStateService: AppStateService) {
  }

  private SaveAccountUserActions(accountId: string, userPermissions: AccountUserActions[]) {
    const userPermission = userPermissions.find(userPermission => userPermission.accountId === accountId);
    if (userPermission) {
      this.sharedStorageService.addUserRoleActions(userPermission.accountId, userPermission.userActionIds);
      this.sharedStorageService.addSubscriptionActions(userPermission.accountId, userPermission.subscriptionActionIds);
      this.sharedStorageService.addAccountRole(userPermission.accountId, userPermission.roleId);
      this.sharedStorageService.addRoleId(userPermission.roleId);
    }
  }

  public loadAccountUserActions(accountId: string): void {
    const actions = this.sharedStorageService.getUserRoleActions(accountId);
    if (actions && actions.length > 0) {
      this.appStateService.publishUserRoleActions(actions);
    } else {
      this.loadUserRoleAndSubscriptionActions(accountId);
    }
  }

  public loadUserRoleAndSubscriptionActions(accountId: string): void {
    this.userDataService.getAccountWiseUserActions()
      .subscribe(userPermissions => {
        userPermissions.forEach(userPermission => {
          this.sharedStorageService.addUserRoleActions(userPermission.accountId, userPermission.userActionIds);
          this.sharedStorageService.addSubscriptionActions(userPermission.accountId, userPermission.subscriptionActionIds);
          this.sharedStorageService.addAccountRole(userPermission.accountId, userPermission.roleId);
          if (userPermission.accountId === accountId) {
            this.appStateService.publishUserRoleActions(userPermission.userActionIds);
            this.appStateService.publishSubscriptionActions(userPermission.subscriptionActionIds);
            this.sharedStorageService.addRoleId(userPermission.roleId);
          }
        });
      });
  }

  public clearActionHistory(onboardedAccountIds: string[]): void {
    onboardedAccountIds.forEach(accountId => {
      this.sharedStorageService.clearActionHistory(accountId);
    });
  }

  public hasUserRoleAccessToPage(requestedActionIds: number[]): Observable<boolean> {
    const accountId = this.sharedStorageService.getAccountId();
    let retValue = false;

    return this.getUserRoleActionsByAccount(accountId).pipe(
      switchMap((actionIdsByAccount: number[]) => {
        requestedActionIds.forEach(actionId => {
          if (actionIdsByAccount?.indexOf(actionId) >= 0) {
            retValue = true;
          }
        });
        return of(retValue);
      })
    );
  }

  public hasSubscriptionAccessToPage(requestedActionIds: number[]): Observable<boolean> {
    const accountId = this.sharedStorageService.getAccountId();
    let retValue = false;

    return this.getSubscriptionActionsByAccount(accountId).pipe(
      switchMap((actionIdsByAccount: number[]) => {
        requestedActionIds.forEach(actionId => {
          if (actionIdsByAccount?.indexOf(actionId) >= 0) {
            retValue = true;
          }
        });
        return of(retValue);
      })
    );
  }

  public getUserRoleActionsByAccount(accountId: string): Observable<number[]> {
    let actionIdsByAccount = this.sharedStorageService.getUserRoleActions(accountId);
    if (actionIdsByAccount && actionIdsByAccount.length > 0) {
      return of(actionIdsByAccount);
    } else {
      return this.userDataService.getAccountWiseUserActions().pipe(
        switchMap((userPermissions: AccountUserActions[]) => {
          this.SaveAccountUserActions(accountId, userPermissions);
          const userAccountActionIds = this.sharedStorageService.getUserRoleActions(accountId);
          actionIdsByAccount = (userAccountActionIds) ? userAccountActionIds : [] as number[];
          return of(actionIdsByAccount);
        }), catchError(() => {
          return of([] as number[]);
        }));
    }
  }

  public getSubscriptionActionsByAccount(accountId: string): Observable<number[]> {
    return this.userDataService.getAccountWiseUserActions().pipe(
      switchMap((userPermissions: AccountUserActions[]) => {
        this.SaveAccountUserActions(accountId, userPermissions);
        const userAccountActionIds = this.sharedStorageService.getSubscriptionActions(accountId);
        return of((userAccountActionIds) ? userAccountActionIds : [] as number[]);
      }), catchError(() => {
        return of([] as number[]);
      }));
  }
}
